@import "../../styles/breakpoints.scss";

.searchContainer {
  width: 83.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.appContent {
  width: 100%;
  line-height: 1.4;
  display: flex;
}

.module {
  width: 100%;
  margin-bottom: 0.625em;
}

.moduleHeading {
  background: #dc0032;
  color: #ffffff;
  padding: 10px;
  border-radius: 0;
}

.moduleHeadingLocal {
  background: #009adf !important;
}

.textHeading {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 0 5px;
  height: auto;
}

.moduleBody {
  background: #e6e7e7;
  font-size: 12px;
  color: #666;
}

.formHeader {
  padding: 10px 0 0 10px;
  font-size: 0.875rem;
}

.formBody {
  padding: 10px;
  margin-top: 5px;
}

.formRow {
  max-width: none;
  display: flex;
}

.formCol1 {
  width: 33.3333%;

  @include media-breakpoint-down(lg) {
    width: 50%;
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.formCol2 {
  width: 16.6666666667%;

  @include media-breakpoint-down(lg) {
    width: 33.3333333333%;
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.lable {
  color: #666;
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 100;
}

.formInput {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  height: 2.3125rem;
  width: 100%;
  border: 0;
  font-size: 0.75rem;

  &:focus {
    outline: 0;
    box-shadow: 0 0 5px #999999;
    border-color: #999999;
  }
}

.actionBar {
  max-width: none !important;
  width: auto !important;
  padding: 10px;
}

.actionCol {
  width: 100%;
}
.button {
  display: block;
  margin-left: auto;
  background: #dc0032;
  border: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 2.3125rem;
  filter: none;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  font-size: 0.8125rem;
  color: #fdfdfd;
  font-family: "PlutoSansDPDLight";
  text-transform: uppercase;
  cursor: pointer;
}

.buttonLocal {
  background: #009adf !important;
}

.moveLeft {
  transform: translateX(-15.625rem);
}

.errorPanel {
  padding: 10px;
  background: #ffffd5;
  color: #e3002a;
  font-weight: 700;
  font-size: 1.1em;
}

.spinnerContainer {
  width: 100%;
  padding: 2rem 0 2rem 0;
  text-align: center;
  background-color: #e6e7e7;

  img {
    width: 10%;
  }
}
