@import "../../styles/breakpoints.scss";

.subNavigation {
  width: 16.6666666667%;
  color: #424143;
  flex: 0 0 auto;

  @include media-breakpoint-down(lg) {
    width: 25%;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.subNavUl {
  margin-left: 0.9375rem;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

.subNavLi {
  font-size: 0.6875rem;
  color: #424143;
  list-style: none;
  margin-bottom: 8px;
}

.subNavAnchor {
  font-family: "PlutoSansDPDLight";

  &:hover {
    color: #dc0032;
  }
}

.active {
  color: #dc0032;
}

.local {
  li {
    a {
      &:hover {
        color: #009adf !important;
      }
    }
  }
}

.activeLocal {
  color: #009adf !important;
}
