@import "../../styles/breakpoints.scss";

.footer {
  padding-bottom: 35px;
  font-family: "PlutoSansDPDLight";
}

.footerHr {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  border: 0;
  height: 1px;
  background: #808285;
  margin-top: 30px;
  margin-bottom: 25px;
}

.footerCol1 {
  width: 100%;
}
.footerCol2 {
  width: 100%;
}

.copyright {
  color: #808285;
  font-size: 0.6875rem;
  margin-bottom: 10px;
  line-height: 1.6;
}

.copyrightImg {
  vertical-align: bottom;
  margin-right: 10px;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.footerUl {
  display: flex;

  li {
    line-height: 18px;
    font-size: 0.6875rem;
    margin-right: 10px;
    margin-left: 10px;
    color: #808285;
    font-weight: 200;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.footerLi:first-child {
  list-style: none;
  margin-left: 0;
}

.footerLi {
  a {
    color: #808285;

    &:hover {
      color: #dc0032;
    }
  }
}

.local {
  li {
    a {
      &:hover {
        color: #009adf !important;
      }
    }
  }
}
