@font-face {
  font-family: "PlutoSansDPD";
  src: url("./fonts/PlutoSansDPDRegular.otf") format("otf"),
    url("./fonts/PlutoSansDPDRegular.ttf") format("truetype");
}
@font-face {
  font-family: "PlutoSansDPDLight";
  src: url("./fonts/PlutoSansDPDLight.otf") format("otf"),
    url("./fonts/PlutoSansDPDLight.ttf") format("truetype");
}

@font-face {
  font-family: "PlutoSansDPDExtraLight";
  src: url("./fonts/PlutoSansDPDExtraLight.otf");
}

.dpd-row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 62.5rem;
}

.dpd-columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.flex {
  display: flex;
}

.nav-container {
  position: relative;
}
