@import "../../styles/breakpoints.scss";

.mainNavigation {
  max-width: 76rem !important;
  overflow: hidden;
  background-color: #e6e7e7;
  max-height: 30px;
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mainNavUL {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

.mainNavLi {
  display: block;
  float: left;
  list-style: none;
  margin: 0;
  padding: 3px 0px;
}

.mainNavAnchor {
  color: #424143;
  padding: 8px 15px;
  margin-top: -1px;
  font-size: 0.75rem;
  vertical-align: middle;
  text-decoration: none;
  font-family: "PlutoSansDPDLight";
  line-height: inherit;

  &:hover {
    color: #ffffff;
    background-color: #dc0032;
  }
}

.local {
  li {
    a {
      &:hover {
        background-color: #009adf !important;
      }
    }
  }
}

.active {
  color: #ffffff;
  background-color: #dc0032;
}

.activeLocal {
  background-color: #009adf !important;
}
