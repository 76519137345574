@import "../../styles/breakpoints.scss";

.container {
  height: 500px;

  @include media-breakpoint-down(md) {
    height: 300px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
