@import "../../styles/breakpoints.scss";

.mobileNav {
  display: none;
  position: relative;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.tabBar {
  color: #ffffff;
  height: 2.8125rem;
  line-height: 2.8125rem;
  position: relative;
  background: #e6e7e7;
}

.tabSection {
  padding: 0 0.625rem;
  position: absolute;
  text-align: center;
  height: 2.8125rem;
  top: 0;
  left: 2.8125rem;
  right: 2.8125rem;

  h1 {
    font-weight: 200;
    font-size: 1rem;
    color: #424143;
    line-height: 2.8125rem;
    margin: 0;
    font-family: "PlutoSansDPDLight";
  }
}

.tabSection2 {
  width: 2.8125rem;
  height: 2.8125rem;
  position: absolute;
  top: 0;
  border: 0;
  right: 0;
}

.menuIcon {
  text-indent: 2.1875rem;
  width: 2.8125rem;
  height: 2.8125rem;
  display: block;
  padding: 0;
  color: #414042;
  position: relative;
  transform: translate3d(0, 0, 0);

  span {
    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 0;
      top: 50%;
      margin-top: -0.5rem;
      left: 0.90625rem;
      box-shadow: 0 0px 0 1px #414042, 0 7px 0 1px #414042, 0 14px 0 1px #414042;
      width: 1rem;
    }
  }
}

.showNav {
  display: inherit !important;
  transform: translateX(0rem) !important;
}

.hideNav {
  display: none !important;
}

.sideNav {
  display: none;
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
  z-index: 1001;
  box-sizing: content-box;
  transition: transform 500ms ease 0s;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-transform: translate(100.5%, 0);
  -webkit-transform: translate3d(100.5%, 0, 0);
  -moz-transform: translate3d(100.5%, 0, 0);
  -ms-transform: translate3d(100.5%, 0, 0);
  -o-transform: translate3d(100.5%, 0, 0);
  transform: translate3d(100.5%, 0, 0);
  right: 0;
  height: 270px;
}

.siteSearch {
  display: flex;
  justify-content: flex-end;

  div:first-child {
    width: 83.33%;
  }

  div {
    &:nth-child(2) {
      width: 16.666%;
    }
  }
}

.searchButton {
  line-height: 1.3rem;
  height: 1.45rem;
  width: 100%;
  margin: 0;
  border: none;
}

.textInput {
  height: 1.45rem;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
  padding: 0px 4px;
  border: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  width: 100%;
  letter-spacing: -0.01em;
}

.active {
  color: #ffffff !important;
}

.offUl {
  list-style-type: none;
  padding: 0;
  margin: 0;
  -webkit-backface-visibility: hidden;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;

  li {
    a {
      font-family: "PlutoSansDPDLight";
      display: block;
      padding: 0.6666666667rem;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #262626;

      &:hover {
        background: #242424;
      }
    }
  }
}

.ul2 {
  margin-left: 1.25rem;

  li::marker {
    color: rgba(255, 255, 255, 0.1);
  }
}

.moveLeft {
  transform: translateX(-15.625rem);
}
