.controls {
  font: 15px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  width: 100%;
}

.container {
  background: rgba(0, 0, 0, 0.75);
  margin: 0;
  color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
}

.routeSummary {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #ddd;
  color: #fff;
  padding: 5px 10px;
  font-size: 15px;
  line-height: 28px;
  font-family: "PlutoSansDPDLight";

  h1 {
    color: #2c2c2c;
    font-weight: 500;
    margin: 0;
    display: inline;
    font-size: inherit;
    line-height: inherit;
  }

  span {
    color: #2c2c2c;
    margin: 0 5px;
    font-size: 15px;
    line-height: 28px;
  }
}

.instructions {
  overflow: visible;
}

.instructionsWrapper {
  max-height: 100%;
}

.steps {
  background-color: #fff;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.6;
  font-family: "PlutoSansDPDExtraLight";
}

.liStep {
  background-color: #fff;
  color: #2c2c2c;
  position: relative;
  cursor: pointer;
  padding: 20px 20px 20px 40px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.after {
  &::after {
    content: "";
    position: absolute;
    top: 45px;
    bottom: -10px;
    border-left: 2px dotted rgba(255, 255, 255, 0.2);
    left: 20px;
    border-color: #2c2c2c;
  }
}

.icon {
  position: absolute;
  left: 12px;
  top: 20px;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-repeat: no-repeat;
  filter: brightness(1%);
}

.distance {
  color: #2c2c2c;
  position: absolute;
  padding: 5px 10px;
  font-size: 12px;
  left: 30px;
  bottom: -10px;
}
