* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "PlutoSansDPD";
}

input {
  font-family: "PlutoSansDPDLight";
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #424143;
}
