@import "../../styles/breakpoints.scss";

.searchContainer {
  width: 83.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.appContent {
  width: 100%;
  line-height: 1.4;
  display: flex;
}

.module {
  width: 100%;
  margin-bottom: 0.625em;
}

.moduleHeading {
  background: #dc0032;
  color: #ffffff;
  padding: 10px;
  border-radius: 0;
}

.localHeading {
  background: #009adf !important;
}

.textHeading {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 0 5px;
  height: auto;
}

.moduleBody {
  background: #e6e7e7;
  font-size: 12px;
  color: #666;
}

.spinnerContainer {
  width: 100%;
  padding: 2rem 0 2rem 0;
  text-align: center;

  img {
    width: 10%;
  }
}

.moveLeft {
  transform: translateX(-15.625rem);
}

.formHeader {
  padding-left: 10px;
  padding-top: 10px;
}

.formLabel {
  color: #666;
  display: block;
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.formBody {
  padding: 10px;
}

.labelCol {
  width: 58.33%;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  select {
    -webkit-appearance: none !important;
    -webkit-border-radius: 0px;
    background-color: #fafafa;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
    background-position: 100% center;
    background-repeat: no-repeat;
    padding: 0.5rem;
    font-size: 0.875rem;
    font-family: "PlutoSansDPDLight";
    line-height: normal;
    border-radius: 0;
    height: 2.3125rem;
    margin: 0 0 1rem 0;
    color: #555;
    width: 100%;
    border: 0;
  }
}

.formHeader2 {
  padding-left: 10px;
}

.depotName {
  font-size: 12px;
  color: #666;
  font-weight: bold;
  line-height: inherit;
}

.depotRow {
  font-family: "PlutoSansDPDLight";
  display: flex;
  flex-wrap: wrap;
}

.depotDetails {
  width: 41.6666666667%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.depotTimes {
  width: 58.3333333333%;
  padding: 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-top: 0.5rem;
  }
}

.openTimeContainer {
  display: flex;
  flex-wrap: wrap;
}

.openTitle {
  width: 41.6666666667%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.openTimeDetails {
  width: 58.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.day {
  width: 33.3333333333%;
  float: left;
}

.time {
  width: 66.6666666667%;
  float: right;
}

.mapRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mapContainer {
  width: 50%;
  height: 500px;

  @include media-breakpoint-down(md) {
    height: 300px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.directionsContainer {
  width: 49%;
  height: 500px;
  overflow: auto;

  @include media-breakpoint-down(md) {
    height: 300px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 10px;
  }
}

.actionBar {
  max-width: none !important;
  width: auto !important;
  padding: 10px;
}

.actionCol {
  width: 100%;
}

.button {
  display: block;
  margin-left: auto;
  background: #dc0032;
  border: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 2.3125rem;
  filter: none;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  font-size: 0.8125rem;
  color: #fdfdfd;
  font-family: "PlutoSansDPDLight";
  text-transform: uppercase;
  cursor: pointer;
}

.buttonLocal {
  background: #009adf !important;
}
