.breadcrumb {
  width: 100%;
}

.breadcrumbUl {
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
  font-family: inherit;
  margin: 0;
  padding: 0;
}

.breadcrumbLi {
  line-height: 18px;
  float: left;
  font-size: 0.6875rem;
  color: #808285;
  list-style: none;
}

.breadcrumbAnchor {
  font-family: "PlutoSansDPDLight";
  color: #808285;

  &:hover {
    color: #dc0032;
  }
}

.local {
  li {
    a {
      &:hover {
        color: #009adf !important;
      }
    }
  }
}

.breadcrumbArrow {
  &::before {
    content: ">";
    display: inline;
    padding-right: 5px;
    padding-left: 5px;
  }
}
