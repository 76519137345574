@import "../../styles/breakpoints.scss";

.header {
  margin-top: 5px;
  margin-bottom: 0.9rem;
}

.navCol {
  height: 23.19px;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.siteSearch {
  display: flex;
  justify-content: flex-end;

  div:first-child {
    width: 83.33%;
  }
}

.searchButton {
  line-height: 1.3rem;
  height: 1.45rem;
  width: 27px;
  margin: 0;
  border: none;
}

.inputContainer {
  width: 16.66%;
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    width: 25%;
  }
}

.textInput {
  height: 1.45rem;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
  padding: 0px 4px;
  border: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  width: 100%;
  letter-spacing: -0.01em;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
}

.claim {
  width: 50%;
  flex: 0 0 auto;

  img {
    display: block;
    margin-left: auto;
  }

  @include media-breakpoint-down(sm) {
    width: 58.33%;
  }
}

.logo {
  width: 50%;
  flex: 0 0 auto;

  @include media-breakpoint-down(sm) {
    width: 33.33%;
  }
}
